<template>
  <div>
    <v-row>
      <v-col xl="4" lg="6" md="12" sm="12">
        <v-card v-if="job">
          <v-card-title>
            Job: {{ job.name }}
          </v-card-title>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Type</v-list-item-title>
                <v-list-item-subtitle>{{ job.type }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Image</v-list-item-title>
                <v-list-item-subtitle>{{ imageNameNice(job.image) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Command</v-list-item-title>
                <v-list-item-subtitle>{{ job.commandType }} {{ job.command }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>SQL Proxy</v-list-item-title>
                <v-list-item-subtitle>{{ sqlProxyName }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Secret</v-list-item-title>
                <v-list-item-subtitle>{{ secretNameNice(job.secret) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Timeout</v-list-item-title>
                <v-list-item-subtitle>{{ job.timeout }} minutes</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Memory</v-list-item-title>
                <v-list-item-subtitle>{{ job.memory || 64 }}MiB</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="job.storage && job.storage > 0">
              <v-list-item-content>
                <v-list-item-title>Ephemeral Storage</v-list-item-title>
                <v-list-item-subtitle>{{ job.storage }}GiB</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Next Run</v-list-item-title>
                <v-list-item-subtitle v-if="job.nextRun">
                  <from-now :value="job.nextRun" />
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>No run scheduled</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer />

            <v-btn
              v-if="job.status !== 99"
              @click="runNext()"
            >
              Run Next
            </v-btn>

            <v-btn
              @click="$router.push({
              name: 'runner-job-upsert',
              params: { jobID: jobID() }
            })"
            >
              Edit Job
            </v-btn>

            <v-btn
              v-if="job.status === 99"
              @click="enable()"
            >
              Enable
            </v-btn>
            <v-btn
              v-else-if="job.status !== 50"
              @click="disable()"
            >
              Disable
            </v-btn>

            <v-btn @click="viewLogs()">
              View Logs
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col xl="4" lg="6" md="12" sm="12">
        <v-card v-if="job">
          <v-card-title>{{ statusName }}</v-card-title>
          <v-card-text>
            <div class="sTimeContain">
              <div class="sTimeInner">
                <v-timeline dense>
                  <v-timeline-item
                    v-for="(runDoc, runIndex) in run"
                    :key="runIndex"
                    :icon="runIcon(runDoc)"
                    :color="runColor(runDoc)"
                    :title="runLog(runDoc)"
                    small
                  >
                    {{ runDetail(runDoc) }}
                    <from-now :value="runDoc.started" />
                  </v-timeline-item>
                </v-timeline>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xl="4" lg="6" md="12" sm="12">
        <v-card v-if="job">
          <v-card-title>Notes</v-card-title>
          <v-card-text style="white-space: pre-line;">{{ job.notes || 'No notes added' }}</v-card-text>
          <v-card-actions>
            <v-dialog
              v-model="notesDialog"
              persistent
              max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Edit Notes
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-textarea
                    v-model="jobNotes"
                    class="pt-5"
                    hide-details
                    label="Job Notes"
                    outlined
                    :rows="10"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="notesDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="saveNotes"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>
            <v-btn
              fab
              dark
              small
              color="primary"
              class="mr-4"
              @click="
                  $router.push({
                    name: 'runner-job-schedule-upsert',
                    params: { jobID: jobID() }
                  })"
            >
              <v-icon dark>fas fa-plus</v-icon>
            </v-btn>
            Job Schedule
            <v-spacer/>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="scheduleTable"
            :search="search"
          >
            <template v-slot:item.edit="{ item }">
              <v-btn
                fab
                dark
                small
                color="primary"
                @click="
                    $router.push({
                      name: 'runner-job-schedule-upsert',
                      params: { jobID: jobID(), scheduleID: item.id }
                    })
                  ">
                <v-icon dark>fas fa-edit</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-btn fab dark small color="primary" @click="deleteSchedule = item">
                <v-icon dark>far fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="deleteScheduleVisible"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Delete Job Schedule</v-card-title>

        <v-card-text>
          Are you sure you want to delete {{ deleteSchedule.name }}?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="deleteSchedule = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="actionDeleteSchedule"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { db } from '../../../../firestore';
import KubernetesImage from '../../../../model/kubernetes/Image';
import Job from '../../../../model/runner/Job';
import KubernetesSecret from '../../../../model/kubernetes/Secret';
import Schedule from '../../../../model/runner/Schedule';
import FromNow from '../../../../components/FromNow';

const kubernetesImage = new KubernetesImage();
const kubernetesSecret = new KubernetesSecret();

export default {
  name: 'Home',
  components: { FromNow },
  data() {
    return {
      deleteSchedule: false,
      deleteScheduleVisible: false,
      headers: [
        {
          text: 'Edit',
          align: 'left',
          value: 'edit',
        },
        {
          text: 'Run When',
          align: 'left',
          value: 'runWhen',
        },
        {
          text: 'Updated',
          align: 'left',
          value: 'updated',
        },
        {
          text: 'Next Run',
          align: 'left',
          value: 'nextRun',
        },
        {
          text: 'Delete',
          align: 'left',
          value: 'delete',
        },
      ],
      job: null,
      jobNotes: "",
      loading: true,
      nextRun: null,
      notesDialog: false,
      run: [],
      schedule: [],
      search: ''
    };
  },
  computed: {
    scheduleTable() {
      const table = [];
      for (const schedule of this.schedule) {
        table.push({
          ...schedule,
          id: schedule.id,
          runWhen: this.runWhen(schedule),
          updated: moment(new Date(schedule.updated.seconds * 1000)).fromNow(),
          nextRun: moment(new Date(schedule.nextRun.seconds * 1000)).fromNow(),
        });
      }
      return table;
    },
    sqlProxyName() {
      if (!this.job.sqlProxy) {
        return 'None'
      }
      if (!Array.isArray(this.job.sqlProxy)) {
        const proxy = Job.sqlProxyList().find(p => p.id === this.job.sqlProxy);
        return proxy.name || `Unknown: ${this.job.sqlProxy}`
      }
      const proxy = this.job.sqlProxy.map(id => Job.sqlProxyList().find(p => p.id === id)?.name || `Unknown: ${id}`);
      return proxy.join(', ');
    },
    statusName() {
      if (!this.job) return 'No Job';
      return Job.statusCodeList()[this.job.status] || 'Unknown';
    },
  },
  watch: {
    deleteSchedule() {
      this.deleteScheduleVisible = this.deleteSchedule !== false;
    },
    job() {
      this.loading = false;
      this.jobNotes = this.job.notes;
    },
  },
  methods: {
    actionDeleteSchedule() {
      db.collection('shepherd-job')
        .doc(this.$route.params.jobID)
        .collection('schedule')
        .doc(this.deleteSchedule.id)
        .delete();
      this.deleteSchedule = false;
    },
    disable() {
      db.collection('shepherd-job').doc(this.jobID()).update({
        status: 99,
        nextRun: null
      });
    },
    enable() {
      db.collection('shepherd-job').doc(this.jobID()).update({
        status: 0
      });
      this.runNext();
    },
    imageNameNice(image) {
      return kubernetesImage.getJobImageNiceName(image);
    },
    secretNameNice(secret) {
      if (!secret) return 'None';
      return kubernetesSecret.getJobSecretNiceName(secret);
    },
    jobID() {
      return this.$route.params.jobID;
    },
    runDetail(run) {
      if (!run) return 'Unknown run';
      if (!run.ended) return 'Run in progress...';
      let runTime = run.ended.seconds - run.started.seconds;
      if (runTime < 200) return `${run.status} in ${runTime} seconds`;
      runTime = Math.round(runTime / 60);
      if (runTime < 200) return `${run.status} in ${runTime} minutes`;
      runTime = Math.round(runTime / 60);
      return `${run.status} in ${runTime} hours`;
    },
    runIcon(run) {
      switch (run.status) {
        case 'success': return 'fal fa-check';
        case 'error': return 'fal fa-times';
        case 'timeout': return 'fal fa-clock'
        case 'crash': return 'fal fa-fire';
        default: return 'far fa-ellipsis-h';
      }
    },
    runLog(run) {
      if (typeof run.log === 'object') return JSON.stringify(run.log);
      return run.log;
    },
    runNext() {
      const currentTime = Math.round((new Date()).getTime() / 1000);
      if (
        this.job.nextRun
        && (this.job.nextRun.seconds - 60) < currentTime
      ) {
        this.$notify({
          type: 'warning',
          title: 'Job Running Soon',
          text: 'This job is already scheduled to run within the next minute',
        });
      } else {
        db.collection('shepherd-job').doc(this.jobID()).update({
          nextRun: new Date(),
          nextRunSchedule: null,
        }).then(() => {
          this.$notify({
            type: 'success',
            title: 'Job Run Requested',
            text: 'This job will run within the next minute (depending on congestion)',
          });
        });
      }
    },
    runColor(run) {
      switch (run.status) {
        case 'success': return 'green';
        case 'error': return 'red';
        case 'timeout': return 'green';
        case 'crash': return 'red';
        default: return 'grey';
      }
    },
    runWhen(schedule) {
      const scheduleObj = new Schedule(schedule);
      return scheduleObj.getRunWhenLabel();
    },
    saveNotes() {
      db.collection('shepherd-job').doc(this.jobID()).update({
        notes: this.jobNotes,
      }).then(() => {
        this.notesDialog = false;
        this.$notify({
          type: 'success',
          title: 'Job Notes Updated',
        });
      });
    },
    viewLogs() {
      window.open(
        `https://console.cloud.google.com/logs/query;query=resource.labels.pod_name:shepob-${this.job.name}?project=digital-intelligence-208511`,
        '_blank',
      );
    },
  },
  firestore() {
    return {
      job: db.collection('shepherd-job')
        .doc(this.jobID()),
      run: db.collection('shepherd-job')
        .doc(this.jobID())
        .collection('run')
        .orderBy("started", "desc")
        .limit(100),
      schedule: db.collection('shepherd-job')
        .doc(this.jobID())
        .collection('schedule'),
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/shepherd-vars.scss";
.sTimeContain {
  direction: rtl;
  overflow:auto;
  height: 400px;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 5px solid $colorOrange;
    height: 10px;
  }
}
.sTimeInner {
  direction: ltr;
}
</style>
