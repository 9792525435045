<template>
  <v-card :disabled="mode === 'loading'">
    <v-card-title v-if="mode === 'loading'">
      Loading...
    </v-card-title>
    <v-card-title v-if="mode === 'create'">
      Create Runner Job Schedule
    </v-card-title>
    <v-card-title v-if="mode === 'edit'">
      Edit Runner Job Schedule
    </v-card-title>
    <v-card-text>
      <runner-job-schedule-select
        mode="minute"
        :load="minute"
        @value="minute = $event"
      />
      <runner-job-schedule-select
        mode="hour"
        :load="hour"
        @value="hour = $event"
      />
      <runner-job-schedule-select
        mode="dayOfWeek"
        :load="dayOfWeek"
        @value="dayOfWeek = $event"
      />
      <runner-job-schedule-select
        mode="dayOfMonth"
        :load="dayOfMonth"
        @value="dayOfMonth = $event"
      />
      <runner-job-schedule-select
        mode="month"
        :load="month"
        @value="month = $event"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        @click="submitForm()"
      >
        {{ mode === 'create' ? 'Create Schedule' : 'Edit Schedule' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { db } from '../../../../../firestore';
import RunnerJobScheduleSelect from '../../../../../components/Runner/Job/Schedule/Select.vue';
import ScheduleMatch from '../../../../../model/runner/ScheduleMatch';

export default {
  components: { RunnerJobScheduleSelect },
  data() {
    return {
      dayOfMonth: [],
      dayOfWeek: [],
      hour: [],
      minute: [],
      month: [],
      mode: 'loading',
    };
  },
  computed: {
    scheduleData() {
      const match = new ScheduleMatch(
        this.month,
        this.dayOfMonth,
        this.dayOfWeek,
        this.hour,
        this.minute,
      );
      return {
        dayOfMonth: this.dayOfMonth,
        dayOfWeek: this.dayOfWeek,
        hour: this.hour,
        minute: this.minute,
        month: this.month,
        updated: new Date(),
        nextRun: match.nextMatch(),
      };
    },
  },
  mounted() {
    if (this.$route.params.jobID && this.$route.params.scheduleID) {
      db.collection('shepherd-job')
        .doc(this.$route.params.jobID)
        .collection('schedule')
        .doc(this.$route.params.scheduleID)
        .get()
        .then((schedule) => {
          if (!schedule.exists) {
            this.$notify({
              type: 'error',
              title: 'Missing Schedule',
              text: `Cannot find a schedule in the system under the ID: ${this.$route.params.jobID}`,
            });
            this.$router.push({ name: 'runner-job-list' });
          } else {
            // eslint-disable-next-line no-console
            console.warn(schedule.data());
            this.dayOfMonth = schedule.data().dayOfMonth;
            this.dayOfWeek = schedule.data().dayOfWeek;
            this.hour = schedule.data().hour;
            this.minute = schedule.data().minute;
            this.month = schedule.data().month;
            this.mode = 'edit';
          }
        });
    } else if (this.$route.params.jobID) {
      this.mode = 'create';
    } else {
      this.$notify({
        type: 'error',
        title: 'Missing Job',
        text: 'No job ID was sent through',
      });
      this.$router.push({ name: 'runner-job-list' });
    }
  },
  methods: {
    cancel() {
      this.$router.push({
        name: 'runner-job-detail',
        params: { jobID: this.$route.params.jobID },
      });
    },
    submitForm() {
      const errors = [];
      if (this.minute.length === 0) errors.push('Please select at least one minute.');
      if (this.hour.length === 0) errors.push('Please select at least one hour.');
      if (this.dayOfWeek.length === 0) errors.push('Please select at least one day of the week.');
      if (this.dayOfMonth.length === 0) errors.push('Please select at least one day of the month.');
      if (this.month.length === 0) errors.push('Please select at least one month.');
      if (errors.length > 0) {
        this.$notify({
          type: 'error',
          title: 'Incomplete Schedule',
          text: errors.join('<br>'),
        });
      } else {
        switch (this.mode) {
          case 'create':
            db.collection('shepherd-job')
              .doc(this.$route.params.jobID)
              .collection('schedule')
              .add(this.scheduleData)
              .then(() => {
                this.$router.push({
                  name: 'runner-job-detail',
                  params: { jobID: this.$route.params.jobID },
                });
              });
            break;

          case 'edit':
            db.collection('shepherd-job')
              .doc(this.$route.params.jobID)
              .collection('schedule')
              .doc(this.$route.params.scheduleID)
              .update(this.scheduleData)
              .then(() => {
                this.$router.push({
                  name: 'runner-job-detail',
                  params: { jobID: this.$route.params.jobID },
                });
              });
            break;
          default:
            this.$notify({
              type: 'error',
              title: 'Unknown Mode',
              text: `Cannot action unknown mode: ${this.mode}`,
            });
            break;
        }
      }
    },
  },
};
</script>
